module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-etracker-analytics/gatsby-browser.js'),
      options: {"plugins":[],"secureCode":"ho9Osg","respectDnt":true,"blockCookies":false,"isCookieConsentEnabled":false,"controlCookieName":"cookie_control_consent"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
